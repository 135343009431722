import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@parashift/ngx-airbrush';
import { DocumentType, ListFilter } from '@parashift/shared/models';
import { DocumentTypeType } from '@parashift/shared/types';
import { ServiceDecorator } from './decorators';
import { BaseApiService, JsonApiQueryData } from './base';
import { QueryParamsService } from '../query-params.service';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';
import { Observable, catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => DocumentType,
  endpointUrl: DocumentTypeType,
})
export class DocumentTypeService extends BaseApiService<DocumentType> {
  className = DocumentTypeService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    super(http, queryParamsService);
    this.baseUrl = this.environment.endpoints.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  getTenantsDocumentTypes(tenant_id: number): Observable<JsonApiQueryData<DocumentType>> {
    const filter = {
      filter: {
        discarded: 'false',
        tenant_id
      },
      sorting: {
        direction: '',
        field: 'title'
      },
      page: { size: 1000 }
    };
    return this.findAll(filter, tenant_id);
  }

  getTenantsValidationDocumentTypes(document_id: string, tenant_id: number): Observable<JsonApiQueryData<DocumentType>> {
    const listFilter = new ListFilter({
      filter: {
        discarded: 'false'
      },
      sorting: {
        direction: '',
        field: 'title'
      },
      page: { size: 1000 }
    });

    const url = `${this.environment.endpoints.individual_extraction}/${this.environment.endpoints.individual_extraction_version}/${tenant_id}/documents/${document_id}/document_types`;
    const httpParams = this.queryParamsService.getParams(listFilter, false, true);
    const request = this.http.get(url, { params: httpParams, observe: 'response' }).pipe(
      map((res: any) => this.successful(res))
    );

    return request.pipe(
      map((res: Response) => (({ data: this.extract(res), meta: this.extractMeta(res) }))),
      map((result) => new JsonApiQueryData(result.data as DocumentType[], { meta: result.meta })),
      catchError((res: any) => this.handleError(res))
    );
  }

  shallowCopyFrom(document_type_id: string, tenant_id: number): Observable<DocumentType> {
    const new_document_type = new DocumentType({});

    const urlVars = {
      postfix: 'shallow_copy_from/:document_type_id',
      document_type_id
    };

    return this.saveRecord(new_document_type, tenant_id, urlVars);
  }

  undiscard(document_type_id: string, tenant_id: number) {
    const urlVars = {
      postfix: ':document_type_id/undiscard',
      document_type_id
    };
    return this.saveRecord(new DocumentType(), tenant_id, urlVars);
  }

  saveRecordWithInheritanceCheck(documentType: DocumentType, tenant_id?: number, changedAttributes: { [name: string]: any } = {}): Observable<DocumentType> {
    if (documentType.tenant_id !== tenant_id) {
      return this.inheritFrom(documentType.id, tenant_id, changedAttributes);
    } else {
      return this.saveRecord(documentType, tenant_id);
    }
  }

  inheritFrom(document_type_id: string, tenant_id: number, changedAttributes: { [name: string]: any } = {}): Observable<DocumentType> {
    const new_document_type = new DocumentType(changedAttributes);

    const urlVars = {
      postfix: 'inherit_from/:document_type_id?include=parent',
      document_type_id
    };

    return this.saveRecord(new_document_type, tenant_id, urlVars);
  }
}
